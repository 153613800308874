import getTheme from 'src/lib/theme';
import { Warning } from '@mui/icons-material';
import config from 'src/lib/config';
import { Grid } from '@mui/material';

export const DEFAULT_LIMIT = 50;

export const DELETE_CLICKED_EVENT = 'gallery:AppBarDeleteClicked';

export const SAVE_CLICKED_EVENT = 'gallery:AppBarSaveClicked';

export const CANCEL_CLICKED_EVENT = 'gallery:AppBarCancelClicked';

export const DEFAULT_GALLERY_ATTTRIBUTE_SELECTION = 'Unmapped';

export enum SCENE_TYPES {
    GALLERY = 'gallery',
}

export enum FILTER_TYPE {
    taxonomy = 1,
    global = 2,
    private = 3,
}

export enum TRANSLATION_PURPOSE {
    All = 'All',
    H1Title = 'H1Title',
    FilterTitle = 'FilterTitle',
    FilterOptionTitle = 'FilterOptionTitle',
    Unknown = 'Unknown',
}

export enum SEARCH_SCENES_TYPE {
    PAGING = 'PAGING',
    SEARCHING = 'SEARCHING',
}

export enum QUICKVIEW_FILTER_TYPE {
    RADIO = 'radio',
}

export enum GALLERY_STATUS {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
    DELETED = 'deleted',
}

export enum VISIBILITY_CRITERIA {
    ALL = 'all',
    ACTIVE_ONLY = 'activeonly',
    ACTIVE_AND_INACTIVE = 'activeandinactive',
}

export enum VALIDATION_RESULT {
    PASSED = 'passed',
    FAILED = 'failed',
    WARNING = 'warning',
}

export enum FILTER_PROPERTY_INIT_TYPE {
    MANUAL = 'manual',
    AUTO = 'auto',
}

export const VALID_TRANSLATION_PURPOSES_FOR_SAVING = [
    TRANSLATION_PURPOSE.H1Title,
    TRANSLATION_PURPOSE.FilterOptionTitle,
    TRANSLATION_PURPOSE.FilterTitle,
];

export const TRANSLATION_DEFAULT_PAGE_SIZE = 8;

export const NOTIFICATION_CHANNEL_DEFAULT_PAGE_SIZE = 8;

export const CONTENT_FILTERS_LIST = [
    'AllColors', 'logoHasPhoto', 'PhotoNumber', 'PhotoNumberAll',
];

export const BASE_EXPERIENCE_TYPE = 'Base';

export const TLP_EXPERIENCE_TYPE = 'TLP';
export const IGP_EXPERIENCE_TYPE = 'IGP';
export const DEFAULT_TLP_EXPERIENCE_TYPE = 'Default_TLP';
export const DEFAULT_IGP_EXPERIENCE_TYPE = 'Default_IGP';

export const EXPERIENCE_TYPES: App.RenderPropertyExperienceType[] = [
    { name: BASE_EXPERIENCE_TYPE, tooltip: 'Render Properties which affect the entire Gallery, unless specified by an Experience Type' },
    { name: 'Gallery', tooltip: 'Standard Gallery experience' },
    { name: TLP_EXPERIENCE_TYPE, tooltip: 'Gallery experience when used as a Indexable Gallery Page' },
    { name: 'ProductPage', tooltip: 'Gallery experience when used as a Product Page' },
];

export const RenderPropertiesSchema: App.RenderProperties = {
    ShowComparativeNames: {
        helperText: 'Use true or false explicitly',
        hideFromList: true,
        values: [
            {
                dbValue: 'true',
                displayValue: 'true',
            },
            {
                dbValue: 'false',
                displayValue: 'false',
            },
        ],
    },
    ContentBackgroundColor: {
        helperText: 'Default is White. Null is Transparent',
        hideFromList: true,
        values: [
            {
                dbValue: 'rgb(#ffffff)',
                displayValue: 'white: rgb(#ffffff)',
            },
            {
                dbValue: null,
                displayValue: 'null: Transparent',
            },
        ],
    },
    TilePreviewSize: {
        helperText: 'Default is Default',
        hideFromList: false,
        values: [
            {
                dbValue: 'Default',
                displayValue: 'Default',
            },
            {
                dbValue: 'Large',
                displayValue: 'Large',
            },
        ],
    },
    FullBleedConfigType: {
        helperText: 'Dynamic is Default',
        hideFromList: false,
        values: [
            {
                dbValue: 'Dynamic',
                displayValue: 'Dynamic',
            },
            {
                dbValue: 'Manual',
                displayValue: 'Manual',
            },
        ],
    },
    PricingPresentationType: {
        helperText: 'Show price for quantity or per piece',
        hideFromList: false,
        values: [
            {
                dbValue: 'asLowAsPricePerPiece',
                displayValue: 'As Low As Price Per Piece',
            },
            {
                dbValue: 'qtyFromPrice',
                displayValue: 'Quantity From Price',
            },
        ],
    },
    FullBleedUploadPopExperience: {
        helperText: 'Show the specified Full Bleed Upload Pop version',
        hideFromList: false,
        values: [
            {
                dbValue: 'v2',
                displayValue: 'v2',
            },
        ],
    },
    DisableFullBleed: {
        helperText: 'Use true or false explicitly',
        hideFromList: false,
        values: [
            {
                dbValue: 'false',
                displayValue: 'false',
            },
            {
                dbValue: 'true',
                displayValue: 'true',
            },
        ],
    },
    DefaultPageSize: {
        helperText: 'Set Default Page Size for Gallery',
        hideFromList: false,
        values: [
            {
                dbValue: '24',
                displayValue: '24',
            },
            {
                dbValue: '48',
                displayValue: '48',
            },
            {
                dbValue: '96',
                displayValue: '96',
            },
        ],
    },
    VortexEnabled: {
        helperText: (
            <Grid
                container
                alignItems="center"
                spacing={1}
                wrap="nowrap"
            >
                <Grid item><Warning color="error" /></Grid>
                <Grid item>
                    Warning! Ensure that this product has been configured with MCP Rendering prior to enabling
                </Grid>
            </Grid>
        ),
        hideFromList: !config.features.Vortex,
        values: [
            {
                dbValue: 'false',
                displayValue: 'false',
            },
            {
                dbValue: 'true',
                displayValue: 'true',
            },
        ],
    },
    ImageCacheVersion: {
        helperText: 'Set Image Cache Version for Gallery',
        hideFromList: false,
    },
    ShowBreadcrumbs: {
        helperText: 'Use true or false explicitly',
        hideFromList: false,
        values: [
            {
                dbValue: 'false',
                displayValue: 'false',
            },
            {
                dbValue: 'true',
                displayValue: 'true',
            },
        ],
    },
    ShowProductHeaderInfo: {
        helperText: 'Use true or false explicitly',
        hideFromList: false,
        values: [
            {
                dbValue: 'false',
                displayValue: 'false',
            },
            {
                dbValue: 'true',
                displayValue: 'true',
            },
        ],
    },
    ShowFAQs: {
        helperText: 'Use true or false explicitly',
        hideFromList: false,
        values: [
            {
                dbValue: 'false',
                displayValue: 'false',
            },
            {
                dbValue: 'true',
                displayValue: 'true',
            },
        ],
    },
    ShowProductInfo: {
        helperText: 'Use true or false explicitly',
        hideFromList: false,
        values: [
            {
                dbValue: 'false',
                displayValue: 'false',
            },
            {
                dbValue: 'true',
                displayValue: 'true',
            },
        ],
    },
    ShowQuicklinks: {
        helperText: 'Use true or false explicitly',
        hideFromList: false,
        values: [
            {
                dbValue: 'false',
                displayValue: 'false',
            },
            {
                dbValue: 'true',
                displayValue: 'true',
            },
        ],
    },
    ShowProductOptionsDescription: {
        helperText: 'Use true or false explicitly',
        hideFromList: false,
        values: [
            {
                dbValue: 'false',
                displayValue: 'false',
            },
            {
                dbValue: 'true',
                displayValue: 'true',
            },
        ],
    },
    ShowPhotoPersonalization: {
        helperText: 'Use true or false explicitly',
        hideFromList: false,
        values: [
            {
                dbValue: 'false',
                displayValue: 'false',
            },
            {
                dbValue: 'true',
                displayValue: 'true',
            },
        ],
    },
    PhotoPersonalizationImage: {
        helperText: 'The image used in Personalization Preview',
        hideFromList: false,
        values: [
            {
                dbValue: 'default',
                displayValue: 'Default',
            },
            {
                dbValue: 'happyHolidays',
                displayValue: 'Happy Holidays',
            },
        ],
    },
    IconographyEnabled: {
        helperText: 'Use true or false explicitly',
        hideFromList: false,
        values: [
            {
                dbValue: 'false',
                displayValue: 'false',
            },
            {
                dbValue: 'true',
                displayValue: 'true',
            },
        ],
    },
    DisableFullBleedUploadForFoilColor: {
        helperText: 'Hide Fullbleed tile when foil color selected',
        hideFromList: false,
        values: [
            {
                dbValue: 'false',
                displayValue: 'false',
            },
            {
                dbValue: 'true',
                displayValue: 'true',
            },
        ],
    },
    VortexPreviewAnimation: {
        helperText: 'Swivel is Default',
        hideFromList: false,
        values: [
            {
                dbValue: 'swivel',
                displayValue: 'Swivel',
            },
            {
                dbValue: 'panToSide',
                displayValue: 'Pan to side',
            },
        ],
    },
    ConsolidateContent: {
        helperText: (
            <Grid
                container
                alignItems="center"
                spacing={1}
                wrap="nowrap"
            >
                <Grid item><Warning color="error" /></Grid>
                <Grid item>
                    Please do not use this feature for now. It is not yet configurable for all galleries.
                </Grid>
            </Grid>
        ),
        hideFromList: false,
        values: [
            {
                dbValue: 'false',
                displayValue: 'false',
            },
            {
                dbValue: 'true',
                displayValue: 'true',
            },
        ],
    },
    SocialMediaGallery: {
        helperText: 'Use true or false explicitly',
        hideFromList: false,
        values: [
            {
                dbValue: 'false',
                displayValue: 'false',
            },
            {
                dbValue: 'true',
                displayValue: 'true',
            },
        ],
    },
    DesignCreationTypes: {
        helperText: 'Select design creation type.',
        hideFromList: false,
        values: [
            {
                dbValue: 'staticOnly',
                displayValue: 'Static Only',
            },
            {
                dbValue: 'staticAndDynamic',
                displayValue: 'Static And Dynamic',
            },
            {
                dbValue: 'dynamicOnly',
                displayValue: 'Dynamic Only',
            },
        ],
    },
};

export const CULTURES = [
    'da-dk',
    'de-at',
    'de-ch',
    'de-de',
    'en-au',
    'en-ca',
    'fr-ca',
    'en-gb',
    'en-ie',
    'en-in',
    'en-nz',
    'en-sg',
    'en-us',
    'es-us',
    'es-es',
    'fi-fi',
    'fr-be',
    'fr-ch',
    'fr-fr',
    'it-ch',
    'it-it',
    'nb-no',
    'nl-be',
    'nl-nl',
    'pt-pt',
    'sv-se',

];

export enum GLOBAL_FILTER_TYPES {
    Radio = 'Radio',
    Checkbox = 'Checkbox',
}

export const GALLERY_URL_BY_CULTURE: App.GalleryUrlDictionary = {
    'en-ie': 'ie',
    'pt-pt': 'pt',
    'es-es': 'es',
    'en-in': 'in',
    'de-at': 'at',
    'fr-be': 'be',
    'nl-be': 'be',
    'de-ch': 'ch',
    'fr-ch': 'ch',
    'it-ch': 'ch',
    'en-nz': 'co.nz',
    'en-gb': 'co.uk',
    'en-au': 'com.au',
    'de-de': 'de',
    'da-dk': 'dk',
    'fi-fi': 'fi',
    'fr-fr': 'fr',
    'it-it': 'it',
    'nl-nl': 'nl',
    'nb-no': 'no',
    'sv-se': 'se',
    'en-sg': 'sg',
    'en-us': 'com',
    'es-us': 'com',
    'en-ca': 'ca',
    'fr-ca': 'ca',
};

export const GALLERY_URL_BY_ENV: App.GalleryUrlDictionary = {
    local: 'devapi',
    training: 'training',
};

export const GALLERY_CONFLUENCE_URL = 'https://vistaprint.atlassian.net/wiki/spaces/';

export const GALLERY_CONFLUENCE_GUIDES = `${GALLERY_CONFLUENCE_URL}GAL/pages/309396442/Gallery+Configuration+User+Guide`;

export const NAV_DRAWER_OPEN = 'navDrawerOpen';

export enum JOB_STATUS {
    DONE = 'Done',
    FAILED = 'Failed',
    ERROR = 'Error'
}

export const NULL_SELECT_OPTION = 'N/A';

export const GLOBAL_STYLES = {
    '&.MuiFormLabel-asterisk': {
        color: getTheme().palette.error.main,
    },
    '&.MuiInputLabel-asterisk': {
        color: getTheme().palette.error.main,
    },
    '&.MuiTableCell-stickyHeader': {
        backgroundColor: `${getTheme().palette.grey[50]} !important`,
    },
};

export enum BUILD_TLP {
    On = 'On',
    Off = 'Off',
    OnlyL0 = 'OnlyL0'
}
