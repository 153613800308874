import {
    Switch,
    Route,
    Redirect,
} from 'react-router-dom';

import config from 'src/lib/config';
import * as routes from 'src/utils/routes';

import CreateGalleryPage from 'src/pages/CreateGallery';
import CreateTranslationGroupPage from 'src/pages/CreateTranslationGroup';
import ManageGalleryPage from 'src/pages/ManageGallery';
import ManageTranslationsPage from 'src/pages/ManageTranslations';
import SearchGalleriesPage from 'src/pages/SearchGalleries';
import SearchTranslationsPage from 'src/pages/SearchTranslations';
import SearchNotificationChannelsPage from 'src/pages/SearchNotificationChannelsPage';
import ManageNotificationChannelsPage from 'src/pages/ManageNotificationChannels';
import CreateNotificationChannelPage from 'src/pages/CreateNotificationChannelPage';

export default (): JSX.Element => (
    <Switch>
        {config.features.Galleries && (
            <Route path={routes.CREATE_GALLERIES} component={CreateGalleryPage} />
        )}
        {config.features.Galleries && (
            <Route path={routes.MANAGE_GALLERIES} component={ManageGalleryPage} />
        )}
        {config.features.Galleries && (
            <Route path={routes.SEARCH_GALLERIES} component={SearchGalleriesPage} />
        )}
        {config.features.Translations && (
            <Route path={routes.CREATE_TRANSLATIONS} component={CreateTranslationGroupPage} />
        )}
        {config.features.Translations && (
            <Route path={routes.MANAGE_TRANSLATIONS} component={ManageTranslationsPage} />
        )}
        {config.features.Translations && (
            <Route path={routes.SEARCH_TRANSLATIONS} component={SearchTranslationsPage} />
        )}
        {config.features.NotificationChannels && (
            <Route path={routes.CREATE_NOTIFICATION_CHANNELS} component={CreateNotificationChannelPage} />
        )}
        {config.features.NotificationChannels && (
            <Route path={routes.MANAGE_NOTIFICATION_CHANNELS} component={ManageNotificationChannelsPage} />
        )}
        {config.features.NotificationChannels && (
            <Route path={routes.SEARCH_NOTIFICATION_CHANNELS} component={SearchNotificationChannelsPage} />
        )}
        {config.features.Galleries && (
            <Redirect from="/" to={routes.SEARCH_GALLERIES} />
        )}
    </Switch>
);
